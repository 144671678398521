#fashion-job-home {
	aside {
		padding: 0;
	}
	> p, h1 {
		display: none;
	}
	.wpjb-widget-with-count {
		li {
			+ li {
				border-top: 1px solid #cccdce;
			}
		}
	}
	.job-type {
		background-size: 98px 100%;
		background-repeat: no-repeat;
		display: block;
		height: 77px;
		padding: 0 0 0 114px;
		border: 0;
		.ui-btn-inner {
			text-align: left;
			padding: 12px 0 0;
		}
		.ui-btn-text {
			font-size: 14px;
			text-transform: none;
		}
		.jobs-count {
			color: #808285;
			font-size: 12px;
			display: block;
		}
		.ui-icon {
			margin: 0;
			top: -1px;
			right: 0;
		}
	}
}