.wpjb-view-job {
	background-image: url('../img/expand.png');
	background-repeat: no-repeat;
	background-position: 100% -1px;
	.wpjb-column-details {
		color: #808285;
		font-size: 14px;
		font-weight: 700;
		padding: 20px 20px 30px;
		border-bottom: 1px solid #cccdce;
		.wpjb-title {
			color: #4d4d4f;
		}
		.wpjb-posted,
		.wpjb-company,
		.wpjb-job-type,
		.wpjb-category,
		.wpjb-salary {
			span {
				font-size: 12px;
				font-weight: 400;
				font-style: italic;
			}
		}
		.wpjb-category {
			a {
				color: #808285;
				text-decoration: none;
			}
		}
	}
	.wpjb-column-desc {
		color: #808285;
		margin-top: 25px;
		padding: 0 20px;
		h3 {
			color: #4d4d4f;
			font-size: 14px;
			font-weight: 700;
			font-family: Verdana, "Helvetica Neue", Helvetica, sans-serif;
			margin-bottom: 5px;
		}
		div, p, ul, ol {
			font-size: 12px;
		}
		p {
			margin: 0;
			padding: 0 5px;
			+ p {
				margin-top: 10px;
			}
		}
		ul, ol {
			margin-bottom: 10px;
			padding-left: 25px;
		}
	}
	.wpjb-column-client {
		color: #808285;
		padding: 0 20px;
		h3 {
			color: #4d4d4f;
			font-size: 14px;
			font-weight: 700;
			font-family: Verdana, "Helvetica Neue", Helvetica, sans-serif;
			margin-bottom: 5px;
		}
		p {
			font-size: 12px;
			margin: 0;
			padding: 0 5px;
			+ p {
				margin-top: 10px;
			}
		}
	}
}


.wpjb-job-apply {
    margin:45px 0 25px 0;
    clear:both;
    .wpjb-job-apply-buttons {
    	text-align: center;
    	width: 100%;
    }
}

a.wpjb-button,
.wpjb-upload-actions a.button {
    background: #F2F2F2;
    display: inline-block;
    padding: 7px 13px 8px;
    color: #444444 !important;
    text-decoration: none;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    cursor: pointer;

    font-weight: bold;
    font-size: 13px;
    line-height: 1;
    font-family: Arial;
    background-image: -moz-linear-gradient(50% 0%, #F5F5F5, #F1F1F1);
}

a.wpjb-button:visited,
.wpjb-upload-actions a.button:visited,
a.wpjb-button:hover,
.wpjb-upload-actions a.button:hover {
    color: #444444 !important;
    text-decoration: none;
}

.wpjb-form .wpjb-error {
    border: 1px solid #DE5400;
    background-color: 	#FFE87C;
}

.wpjb-form .wpjb-required {
    font-weight: bold;
    color: red;
}

.wpjb-form-slider {
    background-color:whitesmoke;
    border:1px solid #D9D9D4;
    margin: 10px 0 0 0;
}

.wpjb-form fieldset {
    margin-bottom: 15px;
    border: 1px solid #D9D9D4;
}

.wpjb-form fieldset legend {
    margin-left: 10px;
    font-weight: bold;
    font-size: 110%;
    padding: 0px 6px;
}

.wpjb-form fieldset legend.wpjb-empty {
    margin: 0;
    padding: 0;
    display:none;
}

.wpjb-form > fieldset > div,
.wpjb-form > fieldset > div > div.wpjb-field
.wpjb-form > fieldset > table {
    padding:5px;
    margin: 5px;
    clear: both;
    overflow:hidden;
}
	.wpjb-element-name-message {
		display: none;
	}

.wpjb-form div.wp-editor-tools {
    padding: 0 10px 0 0;
    margin: 0;
}

.wpjb-form div label.wpjb-label {
    font-weight: normal;
    display: block;
    font-size: 100%;
    margin-bottom: 2px;
    width: 30%;
    float:left;
}

.wpjb-form.wpjb-form-nolines fieldset {
    border:0px;
    margin: 10px 0 10px 0;
    // padding: 0 10px 0 10px;
}

.wpjb-form.wpjb-form-nolines legend {
    padding: 0px 0 10px 0;
    font-size: 1.1em;
    background: none;
    border: none;
    width: 100%;
}

.wpjb-form input {
    margin: 0;
}

.wpjb-form div input[type=text], .wpjb-form div input[type=password] {
    width: 300px;
    margin: 0;
}

.wpjb-form div select {
    width: 85%;
}

.wpjb-form div div.wpjb-field textarea {
    width: 90%;
    height: 270px;
}

.wpjb-form div small {
    display: block;
    font-style: italic;
}

.wpjb-form div.wpjb-input-textarea small {
    margin-left:0%;
}

.wpjb-form div.wpjb-field {
    margin:0;
    padding:0;
    float:left;
    clear:none;
    width:100%;
}

.wpjb-form ul.wpjb-errors {
    margin: 0 0 0 0;
    padding: 0;
}

.wpjb-form ul.wpjb-errors li {
    color: #D54E21;
    margin: 0 0 0.1em 20px;
    padding: 0;
    list-style-type: circle;
}

.wpjb-form .wpjb-element-input-textarea .wpjb-field {
    display: block;
    width: 100%;
}

.wpjb-form .wp-editor-container {
    border: 1px solid #e5e5e5;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.04);
    -moz-box-shadow: 0 1px 1px rgba(0,0,0,.04);
    box-shadow: 0 1px 1px rgba(0,0,0,.04);
}

.wpjb-job-apply form input[type="submit"]:hover{
	 border: 1px solid rgba(0, 0, 0, 0.2);
}
.wpjb-form-slider form label{
	font-size: 14px;
    font-weight: normal;
    line-height: 18px;
	margin-bottom:6px;
	display:block;
}
.wpjb-form-slider form{
	padding:10px 12px;
	box-sizing:border-box;
	-webkit-box-sizing:border-box;
	-o-box-sizing:border-box;
	-ms-box-sizing:border-box;
}
.wpjb-form-slider form input[type="text"],
.wpjb-page-default-form form input[type="text"],
.wpjb-page-default-form form input[type="password"]{
	width:85%;
	background-color: #f0f0f0;
    border: 1px solid #d2d2d2;
    color: #333;
	height:36px;
	line-height:18px;
	font-size:12px;
	padding:0 10px;
	margin:0 0 6px;
	box-sizing:border-box;
	-webkit-box-sizing:border-box;
	-o-box-sizing:border-box;
	-ms-box-sizing:border-box;
	-webkit-appearance:none;
	border-radius:0px;
	box-shadow:none;
	outline:none;
}
.wpjb-form-slider form textarea{
	width:85%;
	background-color: #f0f0f0;
    border: 1px solid #d2d2d2;
    color: #333;
	height:180px;
	line-height:18px;
	font-size:14px;
	padding:10px 10px;
	margin:0 0 6px;
	box-sizing:border-box;
	-webkit-box-sizing:border-box;
	-o-box-sizing:border-box;
	-ms-box-sizing:border-box;
	-webkit-appearance:none;
	border-radius:0px;
	box-shadow:none;
	outline:none;
	resize:none;
}

a.wpjb-button{
	margin:0 0 10px;
}
.wpjb-form div label.wpjb-label {
    font-weight: normal;
    display: block;
    font-size: 12px;
    margin-bottom: 6px !important;
    width: auto;
    float: none !important;
}
.wpjb-form div div.wpjb-field textarea {
    width: 100% !important;
    height: 270px;
    &:focus {
    	box-shadow: none;
    }
}
.wpjb-form div input[type=text], .wpjb-form div input[type=password]{
	width:100% !important;
}
.wpjb-form .wpjb-element-input-textarea .wpjb-field{
	margin: 0;
    padding: 0;
    float: none !important;
    clear: none;
}

p.pull-right a{
	text-decoration:none !important;
	font-size:14px;
	text-transform:uppercase;
	border:1px solid #ACACAC !important;
	color:#8A8A8A;
	padding:6px;
	transition:all 0.2s;
	-webkit-transition:all 0.2s;
	-o-transition:all 0.2s;
	-ms-transition:all 0.2s;
}
p.pull-right a:hover{
	color:#2D2D2D;
	border-color:#2D2D2D !important;
	transition:all 0.2s;
	-webkit-transition:all 0.2s;
	-o-transition:all 0.2s;
	-ms-transition:all 0.2s;
}

.wpjb-upload-inner {
	.wpjb-upload-info,
	.wpjb-glyphs {
		display: none;
	}
	#wpjb-upload-browse-file {
		width: 90%;
		width: calc(100% - 28px);
	}
}

.wpjb-hint {
	font-size: 12px;
}

.wpjb-fieldset-recaptcha {
	.wpjb-label {
		display: none;
	}
}

#wpjb-form-job-apply {
	.wpjb-fieldset-recaptcha {
		.wpjb-element-name-recaptcha_response_field {
			@media (max-width: 360px) {
				margin: 0;
				padding: 0;
				.wpjb-label {
					padding-left: 10px;
				}
			}
		}
	}
	.g-recaptcha {
		> div {
			width: 324px !important;
		}
		@media (max-width: 360px) {
			position: relative;
			left: -7px;
			> div {
				width: 307px !important;
			}
		}
	}
	.ui-btn {
		background-color: buttonface;
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-radius: 0;
		box-shadow: none;
		.ui-btn-inner {
			padding: 0;
		}
		.ui-btn-text {
			font-size: 12px;
			font-weight: 700;
			font-family: Arial;
			text-transform: none;
			text-shadow: none;
		}
	}
}