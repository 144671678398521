#wpjb-overlay {
	display: none;
}

#wpjb-job-list {
	display: block;
	> a {
		background-image: url('../img/expand.png');
		background-repeat: no-repeat;
		background-position: 100% -1px;
		text-decoration: none;
		display: block;
		padding: 16px 0 10px 70px;
		position: relative;
		+ a {
			border-top: 1px solid #cccdce;
		}
	}
	.wpjb-column-date {
		background-image: url('../img/calendar.png');
		background-size: contain;
		background-repeat: no-repeat;
		width: 50px;
		height: 50px;
		position: absolute;
		top: 50%;
		margin-top: -25px;
		left: 10px;
		&.new {
			&::before {
				content: "";
				background-color: #f05ed5;
				display: block;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				position: absolute;
				top: -15px;
				left: 50%;
				margin-left: -5px;
			}
		}
		.wpjb-column-date-month,
		.wpjb-column-date-day {
			text-align: center;
			width: 100%;
			position: absolute;
		}
		.wpjb-column-date-month {
			font-size: 8px;
			text-transform: uppercase;
			top: 10px;
		}
		.wpjb-column-date-day {
			font-size: 14px;
			bottom: 5px;
		}
	}
	.wpjb-column-details {
		font-size: 12px;
		padding-right: 10px;
		.wpjb-title {
			color: #4d4d4f;
		}
		.wpjb-location {
			color: #808285;
		}
		.wpjb-company {
			font-weight: normal;
		}
		.wpjb-salary {
			span {
				font-weight: normal;
			}
		}
	}
}